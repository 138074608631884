import { STATE_TOKEN } from '@sod/store/app.state.model';
import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { PopupService } from '@sod/services/popup.service';
import { Store } from '@ngxs/store';
import { ApiUrl } from './constants';
import { Logout } from '@sod/store/app.action';
import { AppState } from '@sod/store/app.state';
import {HeaderService} from "@sod/services/header.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ApiUrl.BASE_API_URL) private baseUrl: string,
    @Inject(ApiUrl.STREAM_API_URL) private streamUrl: string,
    private router: Router,
    private toast: PopupService,
    private store: Store,
    private readonly header: HeaderService
  ) {
  }

  private whiteList(url: string): boolean {
    if (url.includes(this.streamUrl))
      return true;

    return false;
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const state = this.store.selectSnapshot(STATE_TOKEN);
    const authToken = state?.token?.toString();

    if (this.whiteList(req.url)) {
      return next.handle(req);
    }
    const authReq = req.clone({
      url: req.url,
      headers: req.headers
        .set('Authorization', `Bearer ${authToken || ''}`)

    });
    const url = authReq.url;
    this.header.showLoading(url)
    return next.handle(authReq).pipe(
      finalize(() => this.header.hideLoading(url)),
      catchError((err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 403) {
        this.header.showLoading(url)
        this.store.dispatch(new Logout());
        this.store.reset(AppState)
        this.toast.showToast("The session has expired!", 'error')
          .pipe(
            switchMap(()=>this.router.navigate(['logout'])),
            finalize(() => this.header.hideLoading(url))
        )
        .subscribe();
        } if (err.status === 0) {
          this.toast.showToast("The server has error! Please contact to admin.", 'error').pipe(
            switchMap(() => this.router.navigate(['error'])),
            finalize(() => this.header.hideLoading(url))
          ).subscribe();
        }
        this.header.hideLoading(url);
      if (err.status >= 500 && err.status < 600)
        this.toast.showToast("The server has error! Please contact to admin.", 'error');
      return throwError(() => err);
    }));
  }

}
